import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import React from "react";
import { HiOutlineRefresh } from "react-icons/hi";
import { BsFillDice5Fill, BsFillDice2Fill } from "react-icons/bs";
import { AiFillGitlab } from "react-icons/ai";
import "../Css/Fields.css";
import { useGame } from "../../hooks/hooks";
import { useOptionContext } from "../../contexts/OptionProvider";
import { toast } from "react-toastify";

const showBlueOptions = (dices, field) => {
	const [blueDice] = dices.filter((dice) => dice.color === "blue");
	const [whiteDice] = dices.filter((dice) => dice.color === "white");
	if (blueDice?.location !== "unused") {
		return false;
	}
	if (blueDice?.value + whiteDice?.value === field.value) {
		if (!field.checked) {
			return true;
		}
	} else {
		return false;
	}
};
const showWhiteOptions = (dices, field) => {
	const [blueDice] = dices.filter((dice) => dice.color === "blue");
	const [whiteDice] = dices.filter((dice) => dice.color === "white");

	if (whiteDice?.location !== "unused") {
		return false;
	}
	if (whiteDice?.value + blueDice?.value === field.value) {
		if (!field.checked) {
			return true;
		}
	} else {
		return false;
	}
};

function BlueField(props) {
	const { optionActive } = useOptionContext();
	const { dices, handleFieldClick, state, rolled, joker } = useGame();
	const itemsBlue = [
		{ id: 1012, display: "?", item: "orange", x: 5, y: 1 },
		{ id: 4953, display: "?", item: "yellow", x: 5, y: 2 },
		{ id: 9431, display: <AiFillGitlab />, item: "fox", x: 5, y: 3 },
		{ id: 4236, display: <HiOutlineRefresh />, item: "roll-again", x: 1, y: 4 },
		{ id: 4246, display: "?", item: "green", x: 2, y: 4 },
		{ id: 4136, display: "?", item: "purple", x: 3, y: 4 },
		{ id: 1832, display: "+1", item: "plus-one", x: 4, y: 4 },
	];
	return (
		<div>
			<div className="blue-container">
				<div className="blue-points-container">
					<div className="blue-points-content">
						<div className="point">1</div>
						<div className="point">2</div>
						<div className="point">4</div>
						<div className="point">7</div>
						<div className="point">11</div>
						<div className="point">16</div>
						<div className="point">22</div>
						<div className="point">29</div>
						<div className="point">37</div>
						<div className="point">46</div>
						<div className="point">56</div>
					</div>
					<div className="blue-points-numbers">
						<div className="number">1</div>
						<div className="number">2</div>
						<div className="number">3</div>
						<div className="number">4</div>
						<div className="number">5</div>
						<div className="number">6</div>
						<div className="number">7</div>
						<div className="number">8</div>
						<div className="number">9</div>
						<div className="number">10</div>
						<div className="number">11</div>
					</div>
				</div>
				<TooltipComponent className="description" position="TopCenter" content="blauer + weißer Würfel">
					<div className="display">
						<BsFillDice5Fill className="blue-display-dice" /> +
						<BsFillDice2Fill className="white-display-dice" />
					</div>
				</TooltipComponent>
				{props.fields.map((field) => {
					if (props.player === field.user_game_id) {
						return (
							<div
								key={`blue-field-${field.id}`}
								className={`blue-field row-${field.y} column-${field.x} 
								${
									!props.myField
										? ""
										: (joker.blue && !field.checked) ||
										  (rolled &&
												state.user.id === state.lobby.current_player &&
												((optionActive.blue && showBlueOptions(dices, field)) ||
													(optionActive.white && showWhiteOptions(dices, field))))
										? "possible-choice"
										: ""
								}
								`}
								style={({ gridColumn: ` ${field.x}` }, { gridRow: ` ${field.y + 1}` })}
								onClick={() => {
									!props.myField
										? console.log("not your Field")
										: joker.blue && !field.checked
										? handleFieldClick(field, "joker", "blue")
										: rolled &&
										  state.user.id === state.lobby.current_player &&
										  showBlueOptions(dices, field) &&
										  optionActive.blue
										? handleFieldClick(field, "blue", "blue")
										: rolled &&
										  state.user.id === state.lobby.current_player &&
										  showWhiteOptions(dices, field) &&
										  optionActive.white
										? handleFieldClick(field, "white", "blue")
										: toast.success("It's not possible to use this field at the moment");
								}}>
								<div>{field.checked ? "X" : field.value}</div>
							</div>
						);
					} else {
						return null;
					}
				})}
				{itemsBlue.map((item) => {
					return (
						<div
							key={`item-${item.id}`}
							className={`item-${item.item}`}
							style={({ gridColumn: ` ${item.x} ` }, { gridRow: ` ${item.y + 1}` })}>
							<div>{item.display}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
export default BlueField;

