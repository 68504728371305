import React from "react";
import "./Css/Menu.css";
import { useOptionContext } from "../contexts/OptionProvider";
import { useGame } from "../hooks/hooks";

function Menu() {
	const { setTutorialActive, setOutLayerActive } = useOptionContext();
	const { fields } = useGame();

	return (
		<>
			<div className="menu-container">
				<div
					className="menu-item profile-button"
					onClick={() => {
						console.log(fields);
					}}>
					Profile
				</div>
				<div
					className="menu-item tutorial-button"
					onClick={() => {
						setTutorialActive(true);
						setOutLayerActive(true);
					}}>
					Tutorial
				</div>
			</div>
		</>
	);
}

export default Menu;

