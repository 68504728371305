import React, { useState, useEffect } from "react";
import "./Css/Game.css";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import EndScreen from "../components/EndScreen";

import {
	DiceContainer,
	RollAgain,
	YellowField,
	BlueField,
	GreenField,
	OrangeField,
	PurpleField,
	PlusOne,
	Rounds,
	Chat,
	Menu,
} from "../components/components";

import { useGame } from "../hooks/hooks";
import Leaderboard from "../components/Leaderboard";
import TutorialPopUp from "../components/TutorialPopUp";
import { useOptionContext } from "../contexts/OptionProvider";

function Game() {
	const { setTutorialActive, menuActive, setMenuActive, setOutLayerActive } = useOptionContext();
	function closePopups() {
		setTutorialActive(false);
		setMenuActive(false);
		setOutLayerActive(false);
	}
	const { tutorialActive, outLayerActive } = useOptionContext();
	// eslint-disable-next-line no-empty-pattern
	const { fields, players, user, end } = useGame();
	const [myPlayer, setMyPlayer] = useState({});

	useEffect(() => {
		if (players.length > 0) {
			players.forEach((player) => {
				if (player.player_id === user.id) {
					setMyPlayer(player.game_user_id);
				}
			});
		}
	}, [players, user]);

	/* Get Window size*/
	const [, setWindowDimensions] = useState(getWindowDimensions());
	function getWindowDimensions() {
		const { innerWidth: width, innerHeight: height } = window;
		return {
			width,
			height,
		};
	}
	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div className="game">
			{end && <EndScreen />}
			<div
				className="game-container"
				style={{
					transform: `scale(${
						getWindowDimensions().width / 1500 <= 1 ? getWindowDimensions().width / 1500 : 1
					})`,
				}}>
				<div className="board">
					<YellowField fields={fields.yellowFields} player={myPlayer} myField={true} />
					<BlueField fields={fields.blueFields} player={myPlayer} myField={true} />
					<GreenField fields={fields.greenFields} player={myPlayer} myField={true} />
					<OrangeField fields={fields.orangeFields} player={myPlayer} myField={true} />
					<PurpleField fields={fields.purpleFields} player={myPlayer} myField={true} />
				</div>

				<div className="controlls">
					<Rounds />

					<RollAgain />
					<PlusOne />
					<DiceContainer />
					<Chat />
					<Leaderboard />
				</div>
			</div>
			{/*
			let i = 0;
			{players.map((player) => {
				if (player.player_id !== user.id) {
					i++;
					return (
						<div
							className={`other-players-board board-${i}`}
							key={`bord-${player.player_id}`}
							style={{
								border: player.player_id === state.lobby.current_player ? "5px solid green" : "none",
							}}>
							<div className={`board`}>
								<YellowField
									fields={fields.yellowFields}
									player={player.game_user_id}
									myField={false}
								/>
								<BlueField fields={fields.blueFields} player={player.game_user_id} myField={false} />
								<GreenField fields={fields.greenFields} player={player.game_user_id} myField={false} />
								<OrangeField
									fields={fields.orangeFields}
									player={player.game_user_id}
									myField={false}
								/>
								<PurpleField
									fields={fields.purpleFields}
									player={player.game_user_id}
									myField={false}
								/>
							</div>
							<div className="player-name"> {player.nickname}</div>
						</div>
					);
				} else {
					return null;
				}
			})}
		*/}
			<div
				className={`menu-button ${menuActive ? "active" : ""}`}
				onClick={() => {
					if (!menuActive) {
						setMenuActive(true);
						setOutLayerActive(true);
					}
				}}>
				{menuActive ? (
					<AiOutlineClose
						className="menu-icon close"
						onClick={() => {
							setMenuActive(false);
						}}
					/>
				) : (
					<AiOutlineMenu
						className="menu-icon"
						style={{ marginTop: "12px" }}
						onClick={() => {
							setMenuActive(true);
						}}
					/>
				)}
				{menuActive && <Menu />}
			</div>

			{tutorialActive && <TutorialPopUp />}
			{outLayerActive && (
				<div
					className="out-layer"
					onClick={() => {
						closePopups();
					}}
				/>
			)}
		</div>
	);
}

export default Game;

