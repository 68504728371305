import { useState, useEffect } from "react";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import "./Css/RightSide.css";
import { useGame } from "../hooks/hooks";
import { toast } from "react-toastify";
function PlusOne() {
	const { state, handlePlusOne, setJoker } = useGame();

	const [plusOne, setPlusOne] = useState([
		{
			id: 1,
			status: "locked",
		},
		{
			id: 2,
			status: "locked",
		},
		{
			id: 3,
			status: "locked",
		},
		{
			id: 4,
			status: "locked",
		},
		{
			id: 5,
			status: "locked",
		},
		{
			id: 6,
			status: "locked",
		},
		{
			id: 7,
			status: "locked",
		},
	]);

	useEffect(() => {
		if (state.user_game !== null && state.user_game !== undefined) {
			if (state.user_game.joker_amount) {
				const newPlusOne = plusOne;
				newPlusOne.forEach((plusOne) => {
					if (state.user_game.joker_amount >= plusOne.id) {
						plusOne.status = "unlocked";
					}
					if (state.user_game.joker_used >= plusOne.id) {
						plusOne.status = "used";
					}
				});

				setPlusOne(newPlusOne);
			}
		}
	}, [state, plusOne]);

	return (
		<div className="plus-one-container">
			<div>
				<TooltipComponent
					className="description"
					position="TopCenter"
					content="Joker für jedes Feld | nach 3. Zug einsetzbar">
					<div className="plus-one-display">+1</div>
				</TooltipComponent>
			</div>
			{plusOne.map((item) => {
				return (
					<div
						className={`plus-one ${item.status}`}
						key={`plus-one-${item.id}`}
						onClick={() => {
							item.status === "unlocked" && state.user_game.turn === 0
								? handlePlusOne() &&
								  setJoker({
										yellow: true,
										blue: true,
										green: true,
										orange: true,
										purple: true,
								  })
								: toast.error("Du kannst den Joker erst nach dem 3. Zug einsetzen");
						}}>
						{item.status === "used" ? "X" : ""}
					</div>
				);
			})}
		</div>
	);
}

export default PlusOne;

