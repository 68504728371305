import React from "react";
import {
  ChartComponent,
  LineSeries,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
  Category,
  DataLabel,
} from "@syncfusion/ej2-react-charts";
// import { dailyPlayer } from "../DATA/dailyPlayer";
import "./Css/Stats.css";
import { useGame } from "../contexts/GameContextProvider";
import { useEffect } from "react";
function Stats() {
  const {
    getHighscores,
    getAllGamesPlayed,
    getAllGamesPlayedCount,
    getAllGamesRunningCount,
    getAllGamesTodayCount,
    getMosbachWeather,
  } = useGame();
  useEffect(() => {
    getHighscores();
    getAllGamesPlayedCount();
    getAllGamesTodayCount();
    getAllGamesRunningCount();
    getAllGamesPlayed();
    getMosbachWeather();
    // eslint-disable-next-line
  }, []);

  const {
    gamesData,
    gamesRunningCount,
    gamesTodayCount,
    gamesPlayedCount,
    allTimeHighscores,
    mosbachWeather,
  } = useGame();
  const gamesDataList = [
    { title: "Games Running: ", data: gamesRunningCount },
    { title: "Games Today: ", data: gamesTodayCount },
    { title: "Total Games: ", data: gamesPlayedCount },
  ];
  const chartTextStyle = {
    size: "18px",
    color: "Red",
    textAlignment: "Far",
    textOverflow: "Wrap",
  };
  const chartTitlestyle = {
    fontFamily: "Arial",
    fontStyle: "italic",
    fontWeight: "regular",
    color: "#E27F2D",
    size: "23px",
  };

  console.log(mosbachWeather);

  return (
    <div className="stats">
      <div className="stats-title">Statistics</div>
      <div className="stats-container-wrapper">
        <div className="highscore-container stats-container">
          <div className="highscore-title">Highscores</div>
          {allTimeHighscores.map((highscore, index) => {
            return (
              <div className={` highscore-card`} key={`highscore-${index + 1}`}>
                <div className="highscore-playerName">
                  {index + 1 + `. ` + highscore.nick_name}
                </div>
                <div className="highscore-points">{highscore.points}</div>
              </div>
            );
          })}
        </div>
        <div className="admin-container stats-container">
          <ChartComponent
            id="charts"
            title="Games per Month"
            titleStyle={chartTitlestyle}
            primaryXAxis={{ valueType: "Category", title: "Month" }}
            primaryYAxis={{ title: "Games" }}
            // background="white"
            palettes={["black"]}
            textStyle={chartTextStyle}
          >
            <Inject services={[LineSeries, Category, DataLabel]} />
            <SeriesCollectionDirective>
              <SeriesDirective
                type="Line"
                dataSource={gamesData}
                xName="monthName"
                yName="amount"
                marker={{
                  dataLabel: { visible: true },
                  visible: true,
                }}
              ></SeriesDirective>
            </SeriesCollectionDirective>
          </ChartComponent>
        </div>
        <div className="data-container stats-container">
          {gamesDataList.map((data, index) => {
            return (
              <div className="data-card" key={`data-${index + 1}`}>
                <div className="data-title">{data.title}</div>
                <div className="data-data">{data.data}</div>
              </div>
            );
          })}
        </div>
        <div className="weather-container stats-container">
          <div className="weather-title">Weather Mosbach</div>
          {mosbachWeather.map((weather, index) => {
            return (
              <div className="weather-card" key={`weather-${index + 1}`}>
                <div className="weather-data">
                  {weather.time + ":00"}
                  <br />
                  {weather.temp + "°C"}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Stats;
