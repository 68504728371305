import React, { useState, useEffect } from "react";
import "./Css/RightSide.css";
import "./Css/Fields.css";
import { useGame } from "../hooks/hooks";
import { HiOutlineRefresh } from "react-icons/hi";
import { useOptionContext } from "../contexts/OptionProvider";

function Rounds() {
	const { setOptionActive } = useOptionContext();

	const { state, endTurn, setRolled, user, skipTurn } = useGame();
	// eslint-disable-next-line no-unused-vars
	const [rounds, setRounds] = useState([
		{
			round: 1,
			item: "roll-again",
			itemDisplay: <HiOutlineRefresh />,
			status: "pending",
		},
		{
			round: 2,
			item: "plus-one",
			itemDisplay: "+1",
			status: "pending",
		},
		{
			round: 3,
			item: "roll-again",
			itemDisplay: <HiOutlineRefresh />,
			status: "pending",
		},
		{
			round: 4,
			item: "all-colors",
			itemDisplay: "?",
			status: "pending",
		},
		{
			round: 5,
			item: "",
			itemDisplay: "",
			status: "pending",
		},
		{
			round: 6,
			item: "",
			itemDisplay: "",
			status: "pending",
		},
	]);

	useEffect(() => {
		setRounds((prev) => {
			const newState = [];
			prev.forEach((round) => {
				const newRound = { ...round };
				if (round.status === "running") {
					newRound.status = "finished";
				} else if (round.round === state.lobby.round) {
					newRound.status = "running";
				}
				newState.push(newRound);
			});
			return newState;
		});
	}, [state.lobby.round]);
	return (
		<>
			<div className="rounds-container">
				{rounds.map((round) => {
					return (
						<div className={`round-group round-${round.status}`} key={`round-${round.round}`}>
							<div className="field">{round.status === "finished" ? "X" : round.round}</div>
							<div className={`item-rounds item-${round.item}`}>{round.itemDisplay}</div>
						</div>
					);
				})}
			</div>

			<button
				onClick={() => {
					skipTurn();
				}}
				className="skip-button"
				style={{ opacity: state.lobby.current_player === user.id ? 1 : 0.6 }}>
				Skip
			</button>
			<button
				onClick={() => {
					setRolled(false);
					setOptionActive({
						yellow: false,
						blue: false,
						green: false,
						orange: false,
						purple: false,
						white: false,
					});
					endTurn();
				}}
				className="end-turn-button"
				style={{ opacity: state.lobby.current_player === user.id ? 1 : 0.6 }}>
				End Turn
			</button>
		</>
	);
}

export default Rounds;

