import React, { createContext, useContext, useState } from "react";
const OptionContext = createContext();

export const OptionProvider = ({ children }) => {
	const [menuActive, setMenuActive] = useState(false);
	const [tutorialActive, setTutorialActive] = useState(false);
	const [outLayerActive, setOutLayerActive] = useState(false);
	const [optionActive, setOptionActive] = useState({
		yellow: false,
		blue: false,
		green: false,
		orange: false,
		purple: false,
		white: false,
	});

	return (
		<OptionContext.Provider
			value={{
				menuActive,
				setMenuActive,
				tutorialActive,
				setTutorialActive,
				outLayerActive,
				setOutLayerActive,
				optionActive,
				setOptionActive,
			}}>
			{children}
		</OptionContext.Provider>
	);
};

export const useOptionContext = () => useContext(OptionContext);

