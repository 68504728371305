import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGame } from "../contexts/GameContextProvider.jsx";
import "./Css/Chat.css";

function Chat() {
	let params = useParams();
	const [message, setMessage] = useState("");
	const { sendChatMessage, messages, user } = useGame();

	const handleChange = (event) => {
		setMessage(event.target.value);
	};

	const onSubmit = (e) => {
		e.preventDefault();
		if (message !== "") {
			setMessage("");
			sendChatMessage(message, params.lobbyToken);
		}
	};
	return (
		<div className="chat-container">
			<div className="title">Chat: </div>
			<div className="chat-inner-container">
				<div className="chat">
					{messages.map((message, index) => {
						return (
							<div
								key={`message-${index}`}
								style={{
									color: message.user === user.nick_name ? "#ce982d" : "#1b96d3",
								}}>{`${message.user}: ${message.message}`}</div>
						);
					})}
				</div>
			</div>

			<form onSubmit={onSubmit} className="chat-form">
				<input
					type="text"
					id="chat-input"
					name="chatMassage"
					placeholder="your massage..."
					value={message}
					onChange={handleChange}
					autoComplete="off"
				/>
				<button type="submit">Send</button>
			</form>
		</div>
	);
}

export default Chat;
