import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_SERVER_HOST);
let socketInstance = null;

export const getSocket = () => {
	if (socketInstance) {
		return socketInstance;
	}
	socketInstance = new Promise((resolve) => {
		socket.on("connect", () => {
			console.log(`You connected with id: ${socket.id}`);
			resolve(socket);
		});
	});
	return socketInstance;
};
