import React, { useState, useEffect } from "react";
import { HiOutlineRefresh } from "react-icons/hi";
import "./Css/RightSide.css";
import { useGame } from "../hooks/hooks";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
function RollAgain() {
	const { state } = useGame();

	const [rollAgain, setRollAgain] = useState([
		{
			id: 1,
			status: "locked",
		},
		{
			id: 2,
			status: "locked",
		},
		{
			id: 3,
			status: "locked",
		},
		{
			id: 4,
			status: "locked",
		},
		{
			id: 5,
			status: "locked",
		},
		{
			id: 6,
			status: "locked",
		},
		{
			id: 7,
			status: "locked",
		},
	]);

	useEffect(() => {
		if (state.user_game !== null && state.user_game !== undefined) {
			if (state.user_game.re_dice_amount) {
				const newRollAgain = rollAgain;
				newRollAgain.forEach((rollAgain) => {
					if (state.user_game.re_dice_amount >= rollAgain.id) {
						rollAgain.status = "unlocked";
					}
					if (state.user_game.re_dice_used >= rollAgain.id) {
						rollAgain.status = "used";
					}
				});

				setRollAgain(newRollAgain);
			}
		}
	}, [state, rollAgain]);
	return (
		<div className="roll-again-container">
			<div>
				<TooltipComponent
					className="description"
					position="TopCenter"
					content="neu würfeln | wird beim erneuten Würfeln automaisch verbraucht">
					<div className="roll-again-display">
						<HiOutlineRefresh />
					</div>
				</TooltipComponent>
			</div>

			{rollAgain.map((item) => {
				return (
					<div className={`roll-again ${item.status}`} key={`roll-again-${item.id}`}>
						{item.status === "used" ? "X" : ""}
					</div>
				);
			})}
		</div>
	);
}

export default RollAgain;

