import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getSocket } from "../logic/socket";

const GameContext = createContext();

export const GameContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [fields, setFields] = useState([]);
  const [hightscores, setHightscores] = useState([]);
  const [end, setEnd] = useState(false);
  const [dices, setDices] = useState([]);
  const [rolled, setRolled] = useState(false);
  const [joker, setJoker] = useState({
    yellow: false,
    blue: false,
    green: false,
    orange: false,
    purple: false,
  });
  const [gamesData, setGamesData] = useState([]);
  const [gamesRunningCount, setGamesRunningCount] = useState(0);
  const [gamesTodayCount, setGamesTodayCount] = useState(0);
  const [gamesPlayedCount, setGamesPlayedCount] = useState(0);
  const [allTimeHighscores, setAllTimeHighscores] = useState([]);
  const [mosbachWeather, setMosbachWeather] = useState([]);

  const [state, setState] = useState({
    players: [],
    messages: [],
    user: null,
    user_game: null,
    lobby: null,
    isConnected: false,
  });

  useEffect(() => {
    const registerStartup = async () => {
      const socket = await getSocket();
      const sessionUserStr = localStorage.getItem("user");

      if (
        sessionUserStr &&
        sessionUserStr !== "null" &&
        sessionUserStr !== "undefined"
      ) {
        console.log("LOGIN");
        const sessionUser = JSON.parse(sessionUserStr);
        socket.emit(
          "session-login",
          sessionUser.id,
          sessionUser.session_secret,
          (res) => {
            if (res.error) {
              console.log(res.error);
              localStorage.clear("user");
              setState((s) => ({ ...s, isConnected: true }));
            } else {
              setState((s) => ({ ...s, user: res.data, isConnected: true }));
            }
          }
        );
      } else {
        setState((s) => ({ ...s, isConnected: true }));
      }
    };

    registerStartup();
  }, []);
  const getUserGameData = useCallback(async () => {
    const socket = await getSocket();
    socket.emit("get-user-game-data", state.lobby.id, state.user.id, (res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        setState((s) => ({
          ...s,
          user_game: res.data,
        }));
      }
    });
  }, [state.lobby, state.user]);

  const setUserGameData = useCallback(async () => {
    const socket = await getSocket();
    socket.emit("set-user-game-data", state.lobby.id, state.user.id, (res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        setState((s) => ({
          ...s,
          user_game: res.data,
        }));
      }
    });
  }, [state.lobby, state.user]);
  useEffect(() => {
    const registerListeners = async () => {
      const socket = await getSocket();
      socket.on("player-joined", (player) => {
        setState((s) => {
          const players = [...s.players];
          if (!players.find((p) => p.id === player.id)) {
            players.push(player);
          }
          return { ...s, players };
        });
      });
      socket.on("start-game", (gameData) => {
        console.log(gameData);
        navigate(`/${gameData.lobby.token}/game`);
        setFields(gameData.fields);
        setDices(gameData.dices);

        setState((s) => ({
          ...s,
          lobby: gameData.lobby,
          players: gameData.players,
        }));
        getUserGameData();
      });
    };
    registerListeners();
  }, [navigate, state, getUserGameData]);

  useEffect(() => {
    const setActivePlayer = async () => {
      const socket = await getSocket();

      socket.on("active-player", (gameData) => {
        if (gameData.error) {
          console.log(gameData.error);
        } else {
          setState((s) => ({ ...s, lobby: gameData.lobby }));
          setDices(gameData.dices);
          if (gameData.end) {
            setEnd(true);
          }

          if (gameData.lobby.current_player === state.user?.id) {
            toast.success("It's your turn!");
          }
        }
      });
    };
    setActivePlayer();
    return async () => {
      const socket = await getSocket();
      socket.off("active-player");
    };
  }, [state.user?.id]);

  /**
   * Get The Personal Highscores from Database
   */

  const getPersonalHighscores = useCallback(
    async (score) => {
      const socket = await getSocket();
      socket.emit("get-personal-hightscores", state.user.id, score, (res) => {
        if (res.error) {
          console.log(res.error);
        } else {
          console.log(res.data);
          setHightscores(res.data);
        }
      });
    },
    [state.user?.id]
  );
  /**
   * Sets some userdata of the user like name, image, color whatever.
   * @param {{name: string}} userData
   */
  const setUserData = useCallback(async (userData) => {
    const socket = await getSocket();
    socket.emit("set-user-data", userData);
  }, []);

  const saveUser = useCallback((player) => {
    if (player.session_secret) {
      localStorage.setItem("user", JSON.stringify(player));
      setState((s) => ({ ...s, user: player }));
    }
  }, []);

  /**
   * The current user joins a lobby by the provided room token
   * @param {string} lobbyToken
   */
  const joinLobby = useCallback(
    async (lobbyToken) => {
      const socket = await getSocket();
      socket.emit("join-room", lobbyToken, (res) => {
        if (res.error) {
          console.error(res.error);
        } else {
          const { lobby, player, players } = res.data;
          setState((s) => ({ ...s, players: players, lobby }));
          saveUser(player);
          navigate(`/${res.data.lobby.token}`);
        }
      });
    },
    [navigate, saveUser]
  );

  /**
   * Creates a new lobby where the user is the owner.
   */
  const createLobby = useCallback(async () => {
    const socket = await getSocket();
    socket.emit("create-lobby", (res) => {
      if (res.error) {
        console.error(res.error);
      } else {
        const { lobby, player } = res.data;
        setState((s) => ({ ...s, players: [player], lobby }));
        saveUser(player);
        navigate(`/${lobby.token}`);
      }
    });
  }, [navigate, saveUser]);

  /**
   * Changes the state of the lobby to start-game
   */

  const startGame = useCallback(async () => {
    const socket = await getSocket();

    socket.emit("start-game", state.lobby.token, (res) => {
      if (res.error) {
      } else {
        navigate(`/${res.data.lobby.token}/game`);
        setFields(res.data.fields);
        setDices(res.data.dices);

        setState((s) => ({
          ...s,
          lobby: res.data.lobby,
          players: res.data.players,
        }));
        getUserGameData();
      }
    });
  }, [state.lobby, navigate, getUserGameData]);

  /**
   * Handle End Turn
   */
  const endTurn = useCallback(async () => {
    if (state.lobby.current_player === state.user.id) {
      setJoker({
        yellow: false,
        blue: false,
        green: false,
        orange: false,
        purple: false,
      });
      const socket = await getSocket();
      socket.emit("end-turn", state.lobby.token, (res) => {
        if (res.error) {
          console.log(res.error);
        } else {
          if (res.data.joker === true) {
            setJoker({
              yellow: true,
              blue: true,
              green: true,
              orange: true,
              purple: true,
            });
          }
          if (res.data.end) {
            setEnd(true);
          }
          setState((s) => ({ ...s, lobby: res.data.lobby }));
          setDices(res.data.dices);
          setUserGameData();
        }
      });
    } else {
      toast.success("Not your turn!");
    }
  }, [state.user, state.lobby, setUserGameData]);
  /**
   * Handle Skip Turn
   */
  const skipTurn = useCallback(async () => {
    console.log(state);
    if (state.lobby.current_player === state.user.id) {
      if (state.user_game.turn === 0) {
        toast.success("You Don't have any turns left!");
      } else {
        setJoker({
          yellow: false,
          blue: false,
          green: false,
          orange: false,
          purple: false,
        });
        setRolled(false);
        const socket = await getSocket();
        socket.emit("skip-turn", state.user_game.id, (res) => {
          if (res.error) {
            console.log(res.error);
          } else {
            setState((s) => ({ ...s, user_game: res.data }));
          }
        });
      }
    } else {
      toast.success("Not your turn!");
    }
  }, [state]);

  /**
   * Handle DiecRoll
   */

  const getDices = useCallback(async () => {
    const socket = await getSocket();
    socket.emit("get-dices", state.lobby.id, (res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        setDices(res.data);
      }
    });
  }, [state.lobby]);

  useEffect(() => {
    const setPlayersDices = async () => {
      const socket = await getSocket();
      socket.on("rolled-dices", (rolledDice) => {
        if (rolledDice.error) {
          console.log(rolledDice.error);
        } else {
          ////////////////////////////////////////////////// animation muss noch eingebaut werden //////////////////////////////////////////////////////

          setDices(rolledDice);
        }
      });
    };

    setPlayersDices();
  }, []);
  /**
   * Send the Chat Message to the other players in the lobby
   */

  const sendChatMessage = useCallback(
    async (message, lobbyToken) => {
      const socket = await getSocket();

      let messages = [...state.messages];
      let newMessage = { user: state.user.nick_name, message: message };
      messages.push(newMessage);

      setState((s) => ({ ...s, messages }));
      socket.emit("send-message", newMessage, lobbyToken);
    },
    [state.messages, state.user]
  );
  useEffect(() => {
    const remainingPlayers = async () => {
      const socket = await getSocket();
      socket.on("remaining-players", (players) => {
        setState((s) => ({ ...s, players: players }));
      });
    };
    remainingPlayers();
  }, [state.players]);

  /**
   * Receive the Chat Message from the other players in the lobby
   */
  useEffect(() => {
    const receiveChatMessage = async () => {
      const socket = await getSocket();
      console.log("receiveChatMessage");
      socket.on("send-message-to-all", (message) => {
        console.log("message empfangen: ", message);
        let messages = [...state.messages];
        messages.push(message);
        setState((s) => ({ ...s, messages }));
        console.log("messages: ", messages);
      });
    };
    receiveChatMessage();
  }, [state.messages]);

  /**
   * Handle Field Click
   */

  const handleFieldClick = useCallback(
    async (field, diceColor, fieldColor) => {
      if (
        state.user_game.turn > 0 ||
        joker.yellow ||
        joker.blue ||
        joker.green ||
        joker.orange ||
        joker.purple
      ) {
        setJoker({
          yellow: false,
          blue: false,
          green: false,
          orange: false,
          purple: false,
        });
        const socket = await getSocket();
        socket.emit(
          "send-field",
          field,
          diceColor,
          fieldColor,
          state.lobby.id,
          (res) => {
            if (res.error) {
              console.log(res.error);
            } else {
              setRolled(false);
              setDices(res.data.dices);
              let index;
              let newFields = fields;
              switch (res.data.fieldColor) {
                case "yellow":
                  index = fields.yellowFields.findIndex(
                    (f) => f.id === res.data.field[0].id
                  );
                  newFields.yellowFields[index] = res.data.field[0];
                  break;
                case "blue":
                  index = fields.blueFields.findIndex(
                    (f) => f.id === res.data.field[0].id
                  );
                  newFields.blueFields[index] = res.data.field[0];
                  break;
                case "green":
                  index = fields.greenFields.findIndex(
                    (f) => f.id === res.data.field[0].id
                  );
                  newFields.greenFields[index] = res.data.field[0];
                  break;
                case "orange":
                  index = fields.orangeFields.findIndex(
                    (f) => f.id === res.data.field[0].id
                  );
                  newFields.orangeFields[index] = res.data.field[0];
                  break;
                case "purple":
                  index = fields.purpleFields.findIndex(
                    (f) => f.id === res.data.field[0].id
                  );
                  newFields.purpleFields[index] = res.data.field[0];
                  break;
                default:
                  break;
              }
              if (res.data.joker !== null) {
                switch (res.data.joker) {
                  case "yellow":
                    setJoker((j) => ({ ...j, yellow: true }));
                    break;
                  case "blue":
                    setJoker((j) => ({ ...j, blue: true }));
                    break;
                  case "green":
                    setJoker((j) => ({ ...j, green: true }));
                    break;
                  case "orange":
                    setJoker((j) => ({ ...j, orange: true }));
                    break;
                  case "purple":
                    setJoker((j) => ({ ...j, purple: true }));
                    break;
                  default:
                    break;
                }
              }

              setState((s) => ({ ...s, user_game: res.data.userGame }));
              setFields(newFields);
            }
          }
        );
        socket.emit("get-points", state.players, state.lobby.id, (res) => {
          if (res.error) {
            console.log(res.error);
          } else {
            setState((s) => ({ ...s, players: res.data }));
          }
        });
      } else {
        toast.success("You don't have any turns left!");
      }
    },
    [state.user_game, state.lobby, fields, joker, state.players]
  );

  useEffect(() => {
    const setotherPlayersFields = async () => {
      const socket = await getSocket();
      socket.on("send-field", (gameData) => {
        /*
				let index;
						let newFields = fields;
						switch (res.data.fieldColor) {
							case "yellow":
								index = fields.yellowFields.findIndex((f) => f.id === res.data.field[0].id);
								newFields.yellowFields[index] = res.data.field[0];
								break;
							case "blue":
								index = fields.blueFields.findIndex((f) => f.id === res.data.field[0].id);
								newFields.blueFields[index] = res.data.field[0];
								break;
							case "green":
								index = fields.greenFields.findIndex((f) => f.id === res.data.field[0].id);
								newFields.greenFields[index] = res.data.field[0];
								break;
							case "orange":
								index = fields.orangeFields.findIndex((f) => f.id === res.data.field[0].id);
								newFields.orangeFields[index] = res.data.field[0];
								break;
							case "purple":
								index = fields.purpleFields.findIndex((f) => f.id === res.data.field[0].id);
								newFields.purpleFields[index] = res.data.field[0];
								break;
							default:
								break;
						}
						setFields(newFields);
				*/

        setDices(gameData.dices);
      });
    };
    setotherPlayersFields();
  }, [fields]);

  useEffect(() => {
    const getPoints = async () => {
      const socket = await getSocket();
      socket.on("points", (gameData) => {
        console.log(gameData);
        setState((s) => ({ ...s, players: gameData }));
      });
    };
    getPoints();
  }, []);

  /**
   * Item Use
   */
  const handleRollAgain = useCallback(async () => {
    const socket = await getSocket();
    socket.emit("handle-roll-again", state.user_game.id, (res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        setState((s) => ({ ...s, user_game: res.data }));
      }
    });
  }, [state.user_game]);

  const handlePlusOne = useCallback(async () => {
    const socket = await getSocket();
    socket.emit("handle-plus-one", state.user_game.id, (res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        setState((s) => ({ ...s, user_game: res.data }));
      }
    });
  }, [state.user_game]);

  useEffect(() => {
    const setJokerForAllColors = async () => {
      const socket = await getSocket();
      socket.on("set-joker", () => {
        setJoker({
          yellow: true,
          blue: true,
          green: true,
          orange: true,
          purple: true,
        });
      });
    };
    setJokerForAllColors();
  }, []);

  /**
   * Datenbank abfragen für die Statistiken
   */

  const getAllGamesPlayed = async () => {
    const dataArray = [];
    const monthList = [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ];
    console.log("ello");
    const year = new Date().getFullYear();
    console.log(year);
    const lastYear = year - 1;
    const dateList = [
      new Date(lastYear + "-01-01"),
      new Date(lastYear + "-02-01"),
      new Date(lastYear + "-03-01"),
      new Date(lastYear + "-04-01"),
      new Date(lastYear + "-05-01"),
      new Date(lastYear + "-06-01"),
      new Date(lastYear + "-07-01"),
      new Date(lastYear + "-08-01"),
      new Date(lastYear + "-09-01"),
      new Date(lastYear + "-10-01"),
      new Date(lastYear + "-11-01"),
      new Date(lastYear + "-12-01"),
      new Date(year + "-01-01"),
      new Date(year + "-02-01"),
      new Date(year + "-03-01"),
      new Date(year + "-04-01"),
      new Date(year + "-05-01"),
      new Date(year + "-06-01"),
      new Date(year + "-07-01"),
      new Date(year + "-08-01"),
      new Date(year + "-09-01"),
      new Date(year + "-10-01"),
      new Date(year + "-11-01"),
      new Date(year + "-12-01"),
    ];

    let startingMonth = 12;
    for (let i = 12; i < 25; i++) {
      if (dateList[i] > new Date()) {
        startingMonth = i - 1;

        break;
      }
    }
    const socket = await getSocket();
    socket.emit("get-all-games-played", (allGames) => {
      const allGamesData = allGames.data;
      for (let i = startingMonth - 11; i <= startingMonth; i++) {
        let month = monthList[i];
        const playerAmount = allGamesData.filter(
          (allGamesData) =>
            new Date(allGamesData.ended_at) > dateList[i] &&
            new Date(allGamesData.ended_at) < dateList[i + 1]
        ).length;
        dataArray.push({ monthName: month, amount: playerAmount });
      }
      setGamesData(dataArray);
    });
  };

  const getAllGamesRunningCount = useCallback(async () => {
    const socket = await getSocket();
    socket.emit("get-games-running-count", (allGames) => {
      setGamesRunningCount(allGames.data[0].count);
    });
  }, []);
  const getAllGamesTodayCount = useCallback(async () => {
    const socket = await getSocket();
    socket.emit("get-games-today-count", (allGames) => {
      setGamesTodayCount(allGames.data[0].count);
    });
  }, []);
  const getAllGamesPlayedCount = useCallback(async () => {
    const socket = await getSocket();
    socket.emit("get-all-games-played-count", (allGames) => {
      setGamesPlayedCount(allGames.data[0].count);
    });
  }, []);

  const getHighscores = useCallback(async () => {
    const socket = await getSocket();
    socket.emit("get-highscores", (allHighscores) => {
      setAllTimeHighscores(allHighscores.data);
    });
  }, []);

  const getMosbachWeather = useCallback(async () => {
    const socket = await getSocket();
    socket.emit("get-weather", (res) => {
      const hourly = res.data.hourly;
      let flag = true;
      let counter = 1;
      console.log(res);
      for (let i = 0; i < hourly.time.length; i++) {
        if (new Date(hourly.time[i]) > new Date()) {
          if (flag) {
            flag = false;
            console.log(hourly.time[i]);
            counter++;
            setMosbachWeather((current) => [
              ...current,
              {
                time: new Date(hourly.time[i]).getHours(),
                temp: hourly.temperature_2m[i],
              },
            ]);
          } else {
            flag = true;
          }

          if (counter > 5) {
            break;
          }
        }
      }
    });
    console.log(mosbachWeather);
  }, [mosbachWeather]);

  return (
    <GameContext.Provider
      value={{
        players: state.players,
        user: state.user,
        messages: state.messages,
        setUserData,
        createLobby,
        joinLobby,
        startGame,
        sendChatMessage,
        fields,
        setFields,
        endTurn,
        skipTurn,
        state,
        getDices,
        dices,
        setDices,
        handleFieldClick,
        rolled,
        setRolled,
        getAllGamesPlayed,
        getAllGamesRunningCount,
        gamesData,
        gamesRunningCount,
        handleRollAgain,
        joker,
        setJoker,
        getAllGamesTodayCount,
        gamesTodayCount,
        getAllGamesPlayedCount,
        gamesPlayedCount,
        setEnd,
        end,
        hightscores,
        getPersonalHighscores,
        handlePlusOne,
        allTimeHighscores,
        getHighscores,
        getMosbachWeather,
        mosbachWeather,
      }}
    >
      {state.isConnected && children}
    </GameContext.Provider>
  );
};

export const useGame = () => useContext(GameContext);
