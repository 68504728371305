import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGame } from "../hooks/hooks";
import "./Css/Landing.css";

function Landing() {
	const navigate = useNavigate();
	const { createLobby, joinLobby, startSinglePlayer } = useGame();
	const [lobbyInput, setLobbyInput] = useState("");

	const handleChange = (event) => {
		setLobbyInput(event.target.value);
	};

	const showStatistics = () => {
		navigate("/Statistics");
	};

	const onSubmit = (e) => {
		e.preventDefault();
		navigate(`/${lobbyInput}`);
		joinLobby(lobbyInput);
	};

	return (
		<div className="landing">
			<button className="landing-button" onClick={startSinglePlayer}>
				SinglePlayer
			</button>
			<button className="landing-button" onClick={createLobby}>
				Create Lobby
			</button>
			<form onSubmit={onSubmit} className="join-lobby">
				<input
					type="text"
					id="lobbyInput"
					name="lobbyInput"
					placeholder="Join Lobby"
					value={lobbyInput}
					onChange={handleChange}
					autoComplete="off"
				/>
				<button className="join-button" type="submit">
					Join
				</button>
			</form>
			<button
				className="landing-button"
				onClick={() => {
					showStatistics();
				}}>
				Statistik
			</button>
			<p className="one-dash">
				Powered by{" "}
				<a className="one-dash-link" href="https://onedash.de/" target="_blank" rel="noreferrer">
					OneDash.
				</a>
			</p>
		</div>
	);
}
export default Landing;

