import React from "react";
import { useOptionContext } from "../contexts/OptionProvider";
import { AiOutlineClose } from "react-icons/ai";

function TutorialPopUp() {
	const { setTutorialActive } = useOptionContext();
	const tutorialData = [
		{
			title: "Spielablauf",
			text: (
				<p>
					Alle Spieler spielen nacheinander für 4-6 Runden, je nach Spieleranzahl. Der Spieler der gerade dran
					ist, ist der aktive Spieler, alle anderen sind Passive Spieler. Der Aktive Spieler würfelt mit allen
					6 Würfeln und sucht sich einen davon aus und tragt ihn in die entsprechenden Farbfeld ein. Daraufhin
					fallen alle keineren Würfel weg und der Ausgewählte Würfel kommt auf seinen Würfelfeld. Diese Würfel
					können vom Aktivem Spieler diese Runde nicht mehr Verwendet werden. Mit denn verbliebenen Würfeln,
					würfelt er erneut und wiederholt das Prozedere bis alle Würfelfelder voll sind (Maximal 3 mal) oder
					bis er keine Würfel mehr zur Verfügung hat. Nachdem der aktive Spieler seinen Zug durch einer der
					beiden Aktionen beendet sind die Passiven Spieler dran. Sie dürfen sich nun einen Würfen aus dem
					Ablagestapel des Aktiven Spielers aussuchen und ihn auf ihre Spielbretter einsetzen. Durch diese
					Aktion wird der Würfel nicht verbraucht, es können also mehrere Passive Spieler den gleichen Würfel
					auswählen. Haben alle Passiven Spieler einen Würfel ausgewählt, wird der nächste Passive Spieler zum
					aktivem Spieler und es beginnt von Vorne.
				</p>
			),
		},
		{
			title: "Die Farben",
			text: (
				<div>
					Es gibt 6 Würfel, wobei jeder Würfel für sein Farbfeld verwendet wird. der weiße Würfel fungiert als
					Joker und kann in jedes Feld eingesetzt werden.
					<div>
						<div className="headline">Gelb</div>
						<p>
							<div className="bold">Farbregel:</div> Im gelben Feld kann die entsprechde Augenzahl auf dem
							Würfel Abgekreuzt werden.
						</p>
						<p>
							<div className="bold">Punkte:</div> Die Punkte erhält man, wenn man in einer vertikal Reine
							alle Zahlen abkreuzt
						</p>
						<p>
							<div className="bold">Items:</div> Die Items erhält man, wenn man in einer horizontale Reihe
							alle Zahle abkreuzt
						</p>
					</div>
					<div>
						<div className="headline">Blau</div>
						<p>
							<div className="bold">Farbregel:</div> Im blauen Feld werden die Augenzahlen vom weißen und
							blauen Würfel addiert und die Summe der beiden kann abgekreuzt werden, dabei ist es egal, wo
							sich der nicht benutzte würfel befindet
						</p>
						<p>
							<div className="bold">Punkte:</div> Die Punkte erhält man jenachdem vieiele Zahlen abkreuzt
							würden
						</p>
						<p>
							<div className="bold">Items:</div> Die Items erhält man, durch abkreuzen einer kompletten
							Reihe
						</p>
					</div>
					<div>
						<div className="headline">Grün</div>
						<p>
							<div className="bold">Farbregel:</div> Im grünen Feld muss von links nach rechts Abgekreuzt
							werden, wobei kein Feld übersprungen werden darf. Dabei muss der Würfel größer oder gleich
							der der Vorgegeben Zahl im Feld sein
						</p>
						<p>
							<div className="bold">Punkte:</div> Die Punkte erhält man jenachdem vieiele Zahlen abkreuzt
							würden
						</p>
						<p>
							<div className="bold">Items:</div> Unter manchen Feldern stehen Items, diese werden beim
							Abkreuzen des Feldes freigeschaltet
						</p>
					</div>
					<div>
						<div className="headline">Orange</div>
						<p>
							<div className="bold">Farbregel:</div> Im orangenen Feld müssen von links nach rechts die
							Würfel eingetragen werden, wobei kein Feld übersprungen werden darf. Bei Feldern mit einem
							X2/X3 wird die Augezahl multipiziert
						</p>
						<p>
							<div className="bold">Punkte:</div> Die Punkte ensprechen der Summe der Eingetragenen Würfel
						</p>
						<p>
							<div className="bold">Items:</div> Unter manchen Feldern stehen Items, diese werden beim
							Würfel eintragen ins Feldes freigeschaltet
						</p>
					</div>
					<div>
						<div className="headline">Violett</div>
						<p>
							<div className="bold">Farbregel:</div> Im violetten Feld müssen von links nach rechts die
							Würfel eingetragen werden, wobei kein Feld übersprungen werden darf. Der Eingetragen Würfel
							muss immer größer sein, wie der Würfel davor. Nach dem Eintragen einer 6, darf wieder mit
							einer 1 angefangen werden.
						</p>
						<p>
							<div className="bold">Punkte:</div> Die Punkte ensprechen der Summe der Eingetragenen Würfel
						</p>
						<p>
							<div className="bold">Items:</div> Unter manchen Feldern stehen Items, diese werden beim
							Würfel eintragen ins Feldes freigeschaltet
						</p>
					</div>
				</div>
			),
		},
		{
			title: "Items",
			text: (
				<div>
					<div>
						<div className="bold">?-Boni:</div> Man darf ein belibiges Feld der Farbe abkreuzen
					</div>
					<div>
						<div className="bold">Neu Würfeln:</div> Man darf neu Würfeln
					</div>
					<div>
						+<div className="bold">+1:</div> Man darf einen Beliebigen Würfel egal wo er sich befindet
						verwenden und im entsprechend Feld eintragen. Es ist nach einer Runde Einsetztbar (egal ob
						Aktiver oder Passiver Player){" "}
					</div>
				</div>
			),
		},
	];

	return (
		<div className="tutorial-popup">
			<span className="title">Tutorial</span>
			<AiOutlineClose
				className="tutorial-close close"
				onClick={() => {
					setTutorialActive(false);
				}}
			/>

			{tutorialData.map((section) => {
				return (
					<div className="tutorial-section" key={section.title}>
						<div className="tutorial-title">
							<h3>{section.title}</h3>
						</div>
						<div className="tutorial-text">{section.text}</div>
					</div>
				);
			})}
		</div>
	);
}

export default TutorialPopUp;

/*
<p>
					Jeder Spieler erhält am Anfang jedes Zuges ein Item, ausserdem kann er auf dem Spielbrett durch
					geschicktes abkreuzen seiner Felder Items freischalten. Insgesamt gibt es vier Verschiedene Items:
					Neu-Würfel, Zusatz-Würfel, ?-Bonus und denn Fuchs. Das Neu-Würfel erlaubt es dem Spieler seine
					gewürfelten Würfel nocheinmal zu würfel um erneut in das Herz der Würfel zu glauben. Wenn
					Freigeschaltet wird ein Kreis in der reihe des Items Schwarz umrandet, wenn benutzt wird der Schwarz
					umrandete Kreis abgekreuzt. Der Zusatz-Würfel kann am Ende eines Zuges genutzt werden, während die
					Passiven Spieler sich die Abgeworfenen Würfel des Aktiven Spielers aussuchen dürfen. Bei diesem
					Bonus kann ein weiter Würfel ausgewählt und benutzt werden, hierbei dürfen sogar alle Würfel
					ausgewählt werden und nicht nur die aus dem Ablagestapel. ?-Bonus Item können nur auf dem Spielfeld
					freigeschaltet werden und müssen Sofort eingesetzt werden. Sie sind Joker und mit ihnen können im
					jeweils gleichfarbigem Feld eine beliebige Zahl abgekreutz oder eingetragen werden. Füchse werden
					bis zum Ende des Spiel gesammelt und Multiplizieren die Punkteanzahl der schwächsten Farbe des
					Spielers. Hat man zumbeispiel am Ende des Spiels 16 Punkte in Blau (Und in keiner anderen Farbe
					weniger Punkte) zählt jeder Fuchs nochmals 16 Punkte.
				</p>
*/

