import React from "react";
import "./Css/Points.css";
import { useGame } from "../hooks/hooks";
function Leaderboard() {
	const { players, user } = useGame();
	return (
		<div className="points-container">
			{players !== undefined && players !== null
				? players.map((player) => {
						return player.player_id === user.id ? (
							<div className="points" key={`player-${player.player_id}`}>
								<div className="points-yellow">
									<span>Yellow: </span>
									<span className="numbers">
										{player.points === null ? 0 : player.points.yellowPoints}
									</span>
								</div>
								<div className="points-blue">
									<span>Blue: </span>
									<span className="numbers">
										{player.points === null ? 0 : player.points.bluePoints}
									</span>
								</div>
								<div className="points-green">
									<span>Green: </span>
									<span className="numbers">
										{player.points === null ? 0 : player.points.greenPoints}
									</span>
								</div>
								<div className="points-orange">
									<span>Orange: </span>

									<span className="numbers">
										{player.points === null ? 0 : player.points.orangePoints}
									</span>
								</div>
								<div className="points-purple">
									<span>Purple: </span>
									<span className="numbers">
										{player.points === null ? 0 : player.points.purplePoints}
									</span>
								</div>
								<div className="points-fox">
									<span>Fox: </span>
									<span className="numbers">
										{player.points === null
											? 0
											: player.points.foxes * Math.min(...Object.values(player.points))}
									</span>
								</div>
								<div className="points-total">
									<span>Total: </span>
									<span className="numbers">
										{player.points === null
											? 0
											: player.points.yellowPoints +
											  player.points.bluePoints +
											  player.points.greenPoints +
											  player.points.orangePoints +
											  player.points.purplePoints +
											  player.points.foxes * Math.min(...Object.values(player.points))}
									</span>
								</div>
							</div>
						) : (
							<div className="points other-players" key={`player-${player.player_id}`}>
								<span>{player.nickname} :</span>
								<span className="numbers">
									{player.points === null
										? 0
										: player.points.yellowPoints +
										  player.points.bluePoints +
										  player.points.greenPoints +
										  player.points.orangePoints +
										  player.points.purplePoints +
										  player.points.foxes * Math.min(...Object.values(player.points))}
								</span>
							</div>
						);
				  })
				: null}
		</div>
	);
}

export default Leaderboard;
