import "./App.css";
import { Landing, Lobby, Game, Stats } from "./pages";
import { Route, Routes } from "react-router-dom";
import { useGame } from "./hooks/hooks";
import { GameContextProvider } from "./contexts/GameContextProvider";
import { OptionProvider } from "./contexts/OptionProvider";

function App() {
  useGame();

  return (
    <div className="App">
      <GameContextProvider>
        <OptionProvider>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/:lobbyToken" element={<Lobby />} />

            <Route path="/:lobbyToken/game" element={<Game />} />

            <Route path="/Statistics" element={<Stats />} />
          </Routes>
        </OptionProvider>
      </GameContextProvider>
    </div>
  );
}

export default App;
