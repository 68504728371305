import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGame } from "../contexts/GameContextProvider.jsx";

import "./Css/Lobby.css";
function Lobby() {
	let params = useParams();

	const { players, startGame, joinLobby, sendChatMessage, messages, user } = useGame();
	const [message, setMessage] = useState("");

	useEffect(() => {
		if (params.lobbyToken) {
			joinLobby(params.lobbyToken);
		}
	}, [params.lobbyToken, joinLobby]);

	const handleChange = (event) => {
		setMessage(event.target.value);
	};

	const onSubmit = (e) => {
		e.preventDefault();
		if (message !== "") {
			setMessage("");
			sendChatMessage(message, params.lobbyToken);
		}
	};

	return (
		<div className="lobby">
			<div> Lobby: </div>
			<div className="content-container">
				<div className="left-container-lobby">
					<div className="player-container">
						<div className="title">Players: </div>
						<div className="players">
							{players.map((player) => {
								return <div key={player.id}>{player.nick_name}</div>;
							})}
						</div>
					</div>
					<button id="copy-link-button" onClick={() => navigator.clipboard.writeText(window.location.href)}>
						Copy Link
					</button>
				</div>
				<div className="right-container-lobby">
					<div className="chat-container">
						<div className="title">Chat: </div>
						<div className="chat-inner-container">
							<div className="chat">
								{messages.map((message, index) => {
									return (
										<div
											key={`message-${index}`}
											style={{
												color: message.user === user.nick_name ? "#ce982d" : "#1b96d3",
											}}>{`${message.user}: ${message.message}`}</div>
									);
								})}
							</div>
						</div>

						<form onSubmit={onSubmit} className="chat-form">
							<input
								type="text"
								id="chat-input"
								name="chatMassage"
								placeholder="your massage..."
								value={message}
								onChange={handleChange}
								autoComplete="off"
							/>
							<button type="submit">Send</button>
						</form>
					</div>
					<button id="start-game-button" onClick={startGame}>
						Start Game
					</button>
				</div>
			</div>
		</div>
	);
}

export default Lobby;

