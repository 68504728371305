import React from "react";
import { useGame } from "../contexts/GameContextProvider";
import { useDiceFunctions } from "../hooks/UseDice";
import "./Css/DiceContainer.css";
import { toast } from "react-toastify";
import { useOptionContext } from "../contexts/OptionProvider";
function DiceContainer() {
	const { setOptionActive } = useOptionContext();
	const { rollDices } = useDiceFunctions();
	const { getDices, dices, setDices, state, rolled, setRolled, handleRollAgain, user } = useGame();

	// handles in witch container the used dice is placed
	function usedContainer(tag, condition) {
		return (
			<div className={`dice-box ${tag}`}>
				{dices.map((dice, index) => {
					if (dice.location === "used") {
						let count = 0;
						for (let i = 0; i < index; i++) {
							if (dices[i].location === "used") {
								count++;
							}
						}
						if (count === condition) {
							return (
								<div className={dice.color + " dice"} key={dice.color + tag} onClick={() => {}}>
									{displayDice(dice)}
								</div>
							);
						}
					}
					return null;
				})}
			</div>
		);
	}
	// sets the dots on the dice depending on the value
	function displayDice(dice) {
		return (
			<>
				<span
					className="dice-dot dice-top-left"
					style={{
						display: dice.value === 4 || dice.value === 6 || dice.value === 5 ? "block" : "none",
					}}></span>
				<span
					className="dice-dot dice-top-right"
					style={{
						display:
							dice.value === 2 ||
							dice.value === 3 ||
							dice.value === 4 ||
							dice.value === 5 ||
							dice.value === 6
								? "block"
								: "none",
					}}></span>
				<span
					className="dice-dot dice-middle-left"
					style={{
						display: dice.value === 6 ? "block" : "none",
					}}></span>
				<span
					className="dice-dot dice-middle"
					style={{
						display: dice.value === 1 || dice.value === 3 || dice.value === 5 ? "block" : "none",
					}}></span>

				<span
					className="dice-dot dice-middle-right"
					style={{
						display: dice.value === 6 ? "block" : "none",
					}}></span>
				<span
					className="dice-dot dice-bottom-left"
					style={{
						display:
							dice.value === 2 ||
							dice.value === 3 ||
							dice.value === 4 ||
							dice.value === 5 ||
							dice.value === 6
								? "block"
								: "none",
					}}></span>
				<span
					className="dice-dot dice-bottom-right"
					style={{
						display: dice.value === 4 || dice.value === 5 || dice.value === 6 ? "block" : "none",
					}}></span>
			</>
		);
	}

	return (
		<>
			<div className="dice-container">
				<div className="dice-container-title"> Dice-Container</div>
				{dices.map((dice) => {
					if (dice.location === "unused") {
						return (
							<div
								className={` ${dice.color} ${dice.value} dice  ${dice.selected ? " selected" : ""}`}
								key={dice.color}
								onClick={() => {
									switch (dice.color) {
										case "yellow":
											setOptionActive({
												yellow: true,
												blue: false,
												green: false,
												orange: false,
												purple: false,
												white: false,
											});
											break;
										case "blue":
											setOptionActive({
												yellow: false,
												blue: true,
												green: false,
												orange: false,
												purple: false,
												white: false,
											});
											break;
										case "green":
											setOptionActive({
												yellow: false,
												blue: false,
												green: true,
												orange: false,
												purple: false,
												white: false,
											});
											break;
										case "orange":
											setOptionActive({
												yellow: false,
												blue: false,
												green: false,
												orange: true,
												purple: false,
												white: false,
											});
											break;
										case "purple":
											setOptionActive({
												yellow: false,
												blue: false,
												green: false,
												orange: false,
												purple: true,
												white: false,
											});
											break;
										default:
											setOptionActive({
												yellow: false,
												blue: false,
												green: false,
												orange: false,
												purple: false,
												white: true,
											});
											break;
									}
								}}>
								{displayDice(dice)}
							</div>
						);
					} else {
						return null;
					}
				})}
			</div>

			<div className="out-dice-container">
				<div className="dice-container-title"> Out-Dice-Container</div>
				{dices.map((dice) => {
					if (dice.location === "out") {
						return (
							<div className={dice.color + " dice"} key={dice.color + "out"} onClick={() => {}}>
								{displayDice(dice)}
							</div>
						);
					} else {
						return null;
					}
				})}
			</div>

			<div className="used-dice-container">
				{usedContainer("one", 0)}
				{usedContainer("two", 1)}
				{usedContainer("three", 2)}
			</div>
			<button
				onClick={async () => {
					if (state.lobby.current_player === state.user.id) {
						if (state.user_game.turn !== 0) {
							if (rolled && state.user_game.re_dice_amount - state.user_game.re_dice_used > 0) {
								handleRollAgain();
								roll();
							} else if (!rolled) {
								setRolled(true);
								roll();
							} else {
								toast.success("You don't have any Roll-Again-Items left!");
							}

							function roll() {
								rollDices(dices, setDices);

								// yo eventuell besser lösen yo
								setOptionActive({
									yellow: false,
									blue: false,
									green: false,
									orange: false,
									purple: false,
									white: false,
								});
								setTimeout(() => {
									getDices();
									setTimeout(() => {
										setOptionActive({
											yellow: true,
											blue: true,
											green: true,
											orange: true,
											purple: true,
											white: true,
										});
									}, 80);
								}, 600);
							}
						} else {
							toast.success("You don't have any Turns left!");
						}
					} else {
						toast.success("It's not your Turn!");
					}
				}}
				className="roll-dice-button"
				style={{ opacity: state.lobby.current_player === user.id ? 1 : 0.6 }}>
				Roll Dice
			</button>
		</>
	);
}

export default DiceContainer;

