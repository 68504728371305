import React from "react";
import "../Css/Fields.css";

import { useGame } from "../../hooks/hooks";
import { useOptionContext } from "../../contexts/OptionProvider";
import { AiFillGitlab } from "react-icons/ai";
import { toast } from "react-toastify";

const showYellowOptions = (dices, field, plusOneUsed) => {
	const [yellowDice] = dices.filter((dice) => dice.color === "yellow");

	if (yellowDice?.value === field.value) {
		if (yellowDice?.location === "unused" || plusOneUsed === true)
			if (!field.checked) {
				return true;
			}
	} else {
		return false;
	}
};
const showWhiteOptions = (dices, field, plusOneUsed) => {
	const [whiteDice] = dices.filter((dice) => dice.color === "white");

	if (whiteDice?.value === field.value) {
		if (whiteDice?.location === "unused" || plusOneUsed === true)
			if (!field.checked) {
				return true;
			}
	} else {
		return false;
	}
};

function YellowField(props) {
	const { optionActive, plusOneUsed } = useOptionContext();
	const { dices, handleFieldClick, state, rolled, joker } = useGame();
	const itemsYellow = [
		{ id: 1012, display: "?", item: "blue", x: 5, y: 0 },
		{ id: 4953, display: "?", item: "orange", x: 5, y: 1 },
		{ id: 4236, display: "?", item: "green", x: 5, y: 2 },
		{ id: 9431, display: <AiFillGitlab />, item: "fox", x: 5, y: 3 },
		{ id: 1832, display: "+1", item: "plus-one", x: 5, y: 4 },
	];
	const pointsYellow = [
		{ id: 1234, display: "10", x: 0, y: 5 },
		{ id: 4521, display: "14", x: 1, y: 5 },
		{ id: 9385, display: "16", x: 2, y: 5 },
		{ id: 3712, display: "20", x: 3, y: 5 },
	];
	return (
		<div>
			<div className="yellow-container">
				{props.fields.map((field) => {
					if (props.player === field.user_game_id) {
						return (
							<div
								key={`yellow-field-${field.id}`}
								className={`yellow-field row-${field.x} column-${field.y} ${
									!props.myField
										? ""
										: (joker.yellow && !field.checked) ||
										  (rolled &&
												state.user.id === state.lobby.current_player &&
												((showYellowOptions(dices, field) && optionActive.yellow) ||
													(showWhiteOptions(dices, field) && optionActive.white)))
										? "possible-choice"
										: ""
								}`}
								style={({ gridColumn: ` ${field.y}` }, { gridRow: ` ${field.x}` })}
								onClick={() => {
									!props.myField
										? console.log("not your Field")
										: joker.yellow && !field.checked
										? handleFieldClick(field, "joker", "yellow")
										: rolled &&
										  state.user.id === state.lobby.current_player &&
										  showYellowOptions(dices, field, plusOneUsed) &&
										  optionActive.yellow
										? handleFieldClick(field, "yellow", "yellow")
										: rolled &&
										  state.user.id === state.lobby.current_player &&
										  showWhiteOptions(dices, field, plusOneUsed) &&
										  optionActive.white
										? handleFieldClick(field, "white", "yellow")
										: toast.success("It's not possible to use this field at the moment");
								}}>
								<div>{field.checked ? "X" : field.value}</div>
							</div>
						);
					} else {
						return null;
					}
				})}
				{itemsYellow.map((item) => {
					return (
						<div
							key={`item-${item.id}`}
							className={`item-${item.item}`}
							style={({ gridColumn: ` ${item.x} ` }, { gridRow: ` ${item.y}` })}>
							<div>{item.display}</div>
						</div>
					);
				})}
				{pointsYellow.map((point) => {
					return (
						<div
							key={`point-${point.id}`}
							className="point"
							style={({ gridColumn: ` ${point.x} ` }, { gridRow: ` ${point.y}` })}>
							<div>{point.display}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default YellowField;

