import React from "react";
import "../Css/Fields.css";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { HiOutlineRefresh } from "react-icons/hi";
import { useOptionContext } from "../../contexts/OptionProvider";
import { useGame } from "../../hooks/hooks";
import { AiFillGitlab } from "react-icons/ai";
import { toast } from "react-toastify";

function PurpleField(props) {
	const { dices, handleFieldClick, state, rolled, joker } = useGame();
	const { optionActive } = useOptionContext();
	return (
		<div className="purple-container">
			<TooltipComponent position="TopCenter" content="Würfel größer als Vorheriger">
				<div className="start-field">
					<span className="start-field-arrow"></span>
				</div>
			</TooltipComponent>

			{props.fields.map((field, index) => {
				if (props.player === field.user_game_id) {
					return (
						<div className="field-group" key={`purple-field-${field.id}`}>
							<div
								className={`purple-field ${
									!props.myField
										? ""
										: (joker.purple &&
												field.value === 0 &&
												(index === 0 ||
													index === 11 ||
													index === 22 ||
													index === 33 ||
													props.fields[index - 1].value !== 0)) ||
										  (rolled &&
												state.user.id === state.lobby.current_player &&
												field.value === 0 &&
												(index === 0 || index === 11 || index === 22 || index === 33
													? true
													: props.fields[index - 1].value !== 0) &&
												(dices.filter((dice) => dice.color === "white").pop().value >
													(index === 0 ? 0 : props.fields[index - 1].value) ||
													props.fields[index - 1].value === 6) &&
												dices.filter((dice) => dice.color === "white").pop().location ===
													"unused" &&
												optionActive.white) ||
										  (rolled &&
												state.user.id === state.lobby.current_player &&
												field.value === 0 &&
												(index === 0 || index === 11 || index === 22 || index === 33
													? true
													: props.fields[index - 1].value !== 0) &&
												(dices.filter((dice) => dice.color === "purple").pop().value >
													(index === 0 ? 0 : props.fields[index - 1].value) ||
													props.fields[index - 1].value === 6) &&
												dices.filter((dice) => dice.color === "purple").pop().location ===
													"unused" &&
												optionActive.purple)
										? "possible-choice"
										: ""
								}`}
								onClick={() => {
									!props.myField
										? console.log("not your Field")
										: joker.purple &&
										  field.value === 0 &&
										  (index === 0 ||
												index === 11 ||
												index === 22 ||
												index === 33 ||
												props.fields[index - 1].value !== 0)
										? handleFieldClick(field, "joker", "purple")
										: rolled &&
										  state.user.id === state.lobby.current_player &&
										  field.value === 0 &&
										  (index === 0 || index === 11 || index === 22 || index === 33
												? true
												: props.fields[index - 1].value !== 0) &&
										  (dices.filter((dice) => dice.color === "purple").pop().value >
												(index === 0 ? 0 : props.fields[index - 1].value) ||
												props.fields[index - 1].value === 6) &&
										  dices.filter((dice) => dice.color === "purple").pop().location === "unused" &&
										  optionActive.purple
										? handleFieldClick(field, "purple", "purple")
										: rolled &&
										  state.user.id === state.lobby.current_player &&
										  field.value === 0 &&
										  (index === 0 || index === 11 || index === 22 || index === 33
												? true
												: props.fields[index - 1].value !== 0) &&
										  (dices.filter((dice) => dice.color === "white").pop().value >
												(index === 0 ? 0 : props.fields[index - 1].value) ||
												props.fields[index - 1].value === 6) &&
										  dices.filter((dice) => dice.color === "white").pop().location === "unused" &&
										  optionActive.white
										? handleFieldClick(field, "white", "purple")
										: toast.success("It's not possible to use this field at the moment");
								}}>
								{field.value === 0 ? "" : field.value}
								<div className="smaller-sign">{"<"}</div>
							</div>
							<div className={`purple-fields-item item-${field.item}`}>
								{field.item === null ? (
									""
								) : field.item === "fox" ? (
									<AiFillGitlab />
								) : field.item === "plus-one" ? (
									"+1"
								) : field.item === "roll-again" ? (
									<HiOutlineRefresh />
								) : (
									"?"
								)}
							</div>
						</div>
					);
				} else {
					return null;
				}
			})}
		</div>
	);
}

export default PurpleField;

