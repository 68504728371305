import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useGame } from "../hooks/hooks";
import "./Css/Points.css";
import {
	Category,
	ChartComponent,
	Inject,
	Legend,
	SeriesCollectionDirective,
	SeriesDirective,
	Tooltip,
	StackingColumnSeries,
} from "@syncfusion/ej2-react-charts";

function EndScreen() {
	const navigate = useNavigate();
	const { setEnd, players, hightscores, user, getPersonalHighscores } = useGame();
	useEffect(() => {
		let score = 0;
		players.forEach((player) => {
			if (player.player_id === user.id) {
				score =
					player.points.yellowPoints +
					player.points.bluePoints +
					player.points.greenPoints +
					player.points.orangePoints +
					player.points.purplePoints +
					player.points.foxes * Math.min(...Object.values(player.points));
			}
		});
		getPersonalHighscores(score);
	}, [getPersonalHighscores, players, user.id]);
	const backToMenu = () => {
		navigate("/");
		setEnd(false);
		navigate(0);
	};
	const sorted = [...players].sort(
		(a, b) =>
			b.points.yellowPoints +
			b.points.bluePoints +
			b.points.greenPoints +
			b.points.orangePoints +
			b.points.purplePoints +
			b.points.foxes * Math.min(...Object.values(b.points)) -
			(a.points.yellowPoints +
				a.points.bluePoints +
				a.points.greenPoints +
				a.points.orangePoints +
				a.points.purplePoints +
				a.points.foxes * Math.min(...Object.values(a.points)))
	);

	const data = [];
	players.forEach((player) => {
		data.push({
			player: player.nickname,
			yellow: player.points.yellowPoints,
			blue: player.points.bluePoints,
			green: player.points.greenPoints,
			orange: player.points.orangePoints,
			purple: player.points.purplePoints,
			foxes: player.points.foxes * Math.min(...Object.values(player.points)),
			total:
				player.points.yellowPoints +
				player.points.bluePoints +
				player.points.greenPoints +
				player.points.orangePoints +
				player.points.purplePoints +
				player.points.foxes * Math.min(...Object.values(player.points)),
		});
	});

	return (
		<div className="end-screen">
			<div className="head-line">Game over</div>
			<div className="rund-stats-container">
				<ChartComponent
					width="80%"
					tooltip={{ enable: true }}
					id="charts"
					primaryYAxis={{ lineStyle: { width: 0 } }}
					primaryXAxis={{
						valueType: "Category",
						labelStyle: { color: "white" },
						majorGridLines: { width: 0 },
						lineStyle: { width: 0 },
					}}
					chartArea={{ border: { width: 0 } }}
					palettes={["#f1d712", "#6769af", "#378232", "#ff7b00", "#a76f9f", "#be4f4f", "#fff"]}>
					<Inject services={[StackingColumnSeries, Legend, Category, Tooltip]} />
					<SeriesCollectionDirective>
						<SeriesDirective
							dataSource={data}
							xName="player"
							type="StackingColumn"
							yName="yellow"
							name="yellow"
						/>
						<SeriesDirective
							dataSource={data}
							xName="player"
							type="StackingColumn"
							yName="blue"
							name="blue"
						/>
						<SeriesDirective
							dataSource={data}
							xName="player"
							type="StackingColumn"
							yName="green"
							name="green"
						/>
						<SeriesDirective
							dataSource={data}
							xName="player"
							type="StackingColumn"
							yName="orange"
							name="orange"
						/>
						<SeriesDirective
							dataSource={data}
							xName="player"
							type="StackingColumn"
							yName="purple"
							name="purple"
						/>
						<SeriesDirective
							dataSource={data}
							xName="player"
							type="StackingColumn"
							yName="foxes"
							cornerRadius={{
								topLeft: 10,
								topRight: 10,
							}}
							name="foxes"
						/>
					</SeriesCollectionDirective>
				</ChartComponent>
				<div className="highscore-table">
					<div className="highscore-table-headline">Your Highscores</div>
					{hightscores.map((hightscore, index) => {
						return (
							<div className="score" key={`score-${index}`}>
								<span className="index">{index + 1}:</span>
								<span className="your-highscore-points">{hightscore.points}</span>
							</div>
						);
					})}
				</div>
			</div>
			<div className="winner-text">{sorted[0].nickname} wins the game!</div>
			<button
				className="back-to-menu"
				onClick={() => {
					backToMenu();
				}}>
				Back to Menu
			</button>
		</div>
	);
}

export default EndScreen;
