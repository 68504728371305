export const useDiceFunctions = () => {
	const rollDices = (dices, setDices) => {
		dices.forEach((dice) => {
			if (dice.location === "unused") {
				for (let i = 0; i < 6; i++) {
					setTimeout(() => {
						dice.value = Math.floor(Math.random() * 6) + 1;
						setDices([...dices]);
					}, 100 * i);
				}
			}
		});
	};

	const getPreviousFieldValue = (fields, targetField) => {
		let previousField = null;
		let currentField = null;
		fields.forEach((field) => {
			previousField = currentField;
			currentField = field;
			if (field === targetField) {
				if (previousField === null) {
					console.log("get Previos Field return = " + 0);
					return 0;
				}
				console.log("get Previos Field return = " + previousField.value);
				return previousField.value;
			}
		});
	};

	return {
		rollDices,
		getPreviousFieldValue,
	};
};

