import React from "react";
import "../Css/Fields.css";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { HiOutlineRefresh } from "react-icons/hi";
import { useOptionContext } from "../../contexts/OptionProvider";
import { useGame } from "../../hooks/hooks";
import { AiFillGitlab } from "react-icons/ai";
import { toast } from "react-toastify";

const showGreenOptions = (dices, field) => {
	const [greenDice] = dices.filter((dice) => dice.color === "green");

	if (greenDice?.location !== "unused") {
		return false;
	}
	if (greenDice?.value >= field.condition) {
		if (!field.checked) {
			return true;
		}
	} else {
		return false;
	}
};
const showWhiteOptions = (dices, field) => {
	const [whiteDice] = dices.filter((dice) => dice.color === "white");

	if (whiteDice?.location !== "unused") {
		return false;
	}
	if (whiteDice?.value >= field.condition) {
		if (!field.checked) {
			return true;
		}
	} else {
		return false;
	}
};
function GreenField(props) {
	const { optionActive } = useOptionContext();
	const { dices, handleFieldClick, state, rolled, joker } = useGame();

	return (
		<div className="green-container">
			<TooltipComponent position="TopCenter" content="muss größer oder gleich der vorgegeben Zahl sein">
				<div className="start-field">
					<span className="start-field-arrow"></span>
				</div>
			</TooltipComponent>
			{props.fields.map((field, index) => {
				if (props.player === field.user_game_id) {
					return (
						<div className="field-group" key={`green-field-${field.id}`}>
							<div className="point">{field.points}</div>
							<div
								className={`green-field ${
									!props.myField
										? ""
										: (joker.green &&
												!field.checked &&
												(index === 0 ||
													index === 11 ||
													index === 22 ||
													index === 33 ||
													props.fields[index - 1].checked === true)) ||
										  (rolled &&
												state.user.id === state.lobby.current_player &&
												(index === 0 || index === 11 || index === 22 || index === 33
													? true
													: props.fields[index - 1].checked === true) &&
												((optionActive.green && showGreenOptions(dices, field)) ||
													(optionActive.white && showWhiteOptions(dices, field))))
										? "possible-choice"
										: ""
								}`}
								onClick={() => {
									!props.myField
										? console.log("not your Field")
										: joker.green &&
										  !field.checked &&
										  (index === 0 ||
												index === 11 ||
												index === 22 ||
												index === 33 ||
												props.fields[index - 1].checked === true)
										? handleFieldClick(field, "joker", "green")
										: rolled &&
										  state.user.id === state.lobby.current_player &&
										  (index === 0 || index === 11 || index === 22 || index === 33
												? true
												: props.fields[index - 1].checked === true) &&
										  showGreenOptions(dices, field) &&
										  optionActive.green
										? handleFieldClick(field, "green", "green")
										: rolled &&
										  state.user.id === state.lobby.current_player &&
										  (index === 0 || index === 11 || index === 22 || index === 33
												? true
												: props.fields[index - 1].checked === true) &&
										  showWhiteOptions(dices, field) &&
										  optionActive.white
										? handleFieldClick(field, "white", "green")
										: toast.success("It's not possible to use this field at the moment");
								}}>
								{field.checked ? "X" : `≥${field.condition}`}
							</div>
							<div className={`green-fields-item item-${field.item}`}>
								{field.item === null ? (
									""
								) : field.item === "fox" ? (
									<AiFillGitlab />
								) : field.item === "plus-one" ? (
									"+1"
								) : field.item === "roll-again" ? (
									<HiOutlineRefresh />
								) : (
									"?"
								)}
							</div>
						</div>
					);
				} else {
					return null;
				}
			})}
		</div>
	);
}

export default GreenField;

